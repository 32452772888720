import React, { useEffect, useState } from "react";
import { Button, Checkbox, Input, message, Form, Modal, Tooltip } from "antd";
import Icon from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import firebase from 'firebase';

import {
  hideMessage,
  showAuthLoader,
  userSignIn,
} from "appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";
import { MailOutlined, InfoCircleOutlined } from '@ant-design/icons';

const SignIn = () => {

  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(({ auth }) => auth);
  const [form] = Form.useForm();
  const [resetPw, setResetPW] = useState(false);
  const [email, setEmail] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      history.push('/');
    }
  });

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   props.form.validateFields((err, values) => {
  //     if (!err) {
  //       dispatch(showAuthLoader());
  //       dispatch(userSignIn(values));
  //     }
  //   });
  // };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = values => {
    console.log("finish", values)
    dispatch(showAuthLoader());
    dispatch(userSignIn(values));
  };

  const handleResetPW = async () => {
    if (email.length <= 0) {
      message.error('Email is empty');
      return;
    }

    try {
      await firebase.auth().sendPasswordResetEmail(email);
      message.success('Reset password request submitted, please check your email!');
      setEmail('');
      setResetPW(false);
    } catch (error) {
      console.log(error);
      message.error(error.message);
    }
  }

  const handleCancel = () => {
    setEmail('');
    setResetPW(false);
  }

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">

              {/* <img src={"https://via.placeholder.com/272x395"} alt='Neature'/> */}
              {/* <img src={require("assets/images/state_logo.png")} alt='Neature' /> */}
            </div>
            <div className="gx-app-logo-wid">
              <img alt="logo" src={require("assets/images/museum_logo.png")} />
            </div>
            <div className="gx-app-logo">
              <h1 style={{ color: 'black'}}><center>Admin Dashboard</center></h1>
            </div>
          </div>
          <div className="gx-app-login-content" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">

              <Form.Item
                rules={[{ required: true, message: 'The input is not valid E-mail!' }]} name="email">
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Please input your Password!' }]} name="password">
                <Input type="password" placeholder="Password" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" className="gx-mb-0" htmlType="submit" style={{ backgroundColor: '#8B5E3B', borderColor: '#8B5E3B' }}>
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
              </Form.Item>
              {/* <div className="gx-flex-row gx-justify-content-between">
                  <span>or connect with</span>
                  <ul className="gx-social-link">
                    <li>
                      <GoogleOutlined onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userGoogleSignIn());
                      }}/>
                    </li>
                    <li>
                      <FacebookOutlined  onClick={() => {
                        dispatch(showAuthLoader());
                        dispatch(userFacebookSignIn());
                      }}/>
                    </li>
                  </ul>
                </div> */}
              {/* <span style={{ position: 'absolute', bottom: '10px' }}
                className="gx-text-light gx-fs-sm">Please click <a href="#" onClick={() => setResetPW(true)}>HERE</a> to reset your password.</span> */}
            </Form>
          </div>
          <Modal
            visible={resetPw}
            title="Reset Password"
            okText='Submit'
            centered
            onCancel={handleCancel}
            onOk={handleResetPW}
          >
            <Input
              type="email"
              placeholder="Enter your email address here"
              prefix={<MailOutlined className="site-form-item-icon" />}
              suffix={
                <Tooltip title="A password reset link will be sent to this email">
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              }
              onChange={(e) => setEmail(e.target.value)}
              onPressEnter={handleResetPW}
            />
          </Modal>
          {loader ?
            <div className="gx-loader-view">
              <CircularProgress />
            </div> : null}
          {showMessage ?
            message.error(alertMessage.toString()) : null}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
